import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateFAQ from './CreateFAQ';
import ViewFAQ from './ViewFAQ';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateFAQ from './UpdateFAQ';
import { FAQ_API } from '../../../../../Utilities/APIs/APIs';
import { useParams } from 'react-router-dom';

const types = {
  orderIssues: 'Order-Issues',
  membershipScheme: 'Membership-Scheme',
  delivery: 'Delivery',
  shippingDelivery: 'Shipping-Delivery',
  exchangeReturnsRefunds: 'Exchanges-Returns-Refunds',
  productSizeGuides: 'Product-SizeGuides',
  technical: 'Technical',
  contact: 'Contact-Us'
}

function FAQS() {
 
  const axiosInstance = useAxiosInstance()

  const [faqs, setFAQS] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const { type } = useParams()

  useEffect(() => {

    async function fetchAndsetFAQS() {
      const { data } = await axiosInstance.get(FAQ_API + 'getFaqByType/' + types[type])
      setFAQS(data)
    }
    fetchAndsetFAQS()

  }, [toggleFetch, axiosInstance, type])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>{types[type]} FAQS ({faqs?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'FAQ'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Title' />
          <CRUDth th='Type' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            faqs?.map(faq =>
              <CRUDTableRow key={faq._id}>
                <ShortTextCell text={faq.title} />
                <ShortTextCell text={faq.type} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={faq._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                        tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={faq._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                        tooltipContent={"Edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={faq._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delete"} 
                        tooltipContent={"Delete"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add FAQ`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateFAQ
            type={types[type]}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update FAQ`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateFAQ
            type={types[type]}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View FAQ`}
          setShowModalContent={setShowViewSection}
        >
          <ViewFAQ
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete FAQ`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={FAQ_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default FAQS