import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import {

  RiCloseCircleLine,
  RiDiscordLine,
  RiExchangeLine,
  RiGiftLine,
  RiListSettingsLine,
  RiLuggageCartFill,
  RiPrinterLine,
  RiRepeatLine,
  RiSearchLine,
  RiShip2Line,
  RiTruckLine,
} from "react-icons/ri";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import ViewOrder from "../ViewOrder";
import OrderDelivered from "./OrderDelivered";
import CancelOrder from "./CancelOrder";
import { takaFormatter } from "../../../../../Utilities/Formatter";
import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";
import RefundedFromDelivered from "./RefundedFromDelivered";
import ExchangedFromDelivered from "./ExchangedFromDelivered";
import OrderInvoice from "../../../../Partials/Elements/OrderInvoice/OrderInvoice";
import ChangeOrderStatus from "./ChangeOrderStatus";
import Pagination from "../../../../Partials/Elements/pagination/Pagination";
import AddShippingMedia from "./AddShippingMedia";
import OrderInvoicePackage from "../../../../Partials/Elements/OrderInvoice/Orderinvoicepackage";

function OrdersFromStatus() {
  const axiosInstance = useAxiosInstance();

  const [orders, setOrders] = useState(null);

  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  // const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showProcessingOrder, setShowProcessingOrder] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [showRefundedOrder, setShowRefundedOrder] = useState(false);
  const [showExchangeOrder, setshowExchangeOrder] = useState(false);
  const [showChangeOrderStatus, setShowChangeOrderStatus] = useState(false)
  const [showShippingMedia, setShowShippingMedia] = useState(false)

  const [showInvoice, setShowInvoice] = useState(false)
  const [showInvoicepkg, setShowInvoicepkg] = useState(false)
  const [search, setSearch] = useState('')

  const [toggleFetch, setToggleFetch] = useState(false);
  const [limit, setLimit] = useState(40);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [targetID, setTargetID] = useState(null);
  const { status } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetOrders() {
      const res  = await axiosInstance.get(
        `${ORDERS_API}allOrdersByStatus/${status}?search=${search}&page=${currentPage}&limit=${limit}`);
      console.log("check the axios error", res)
      setTotalPages(res.data?.totalPages)
      setOrders(res.data?.orders);
    }
    fetchAndSetOrders();
  }, [toggleFetch, axiosInstance, status, search, currentPage]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  function handleClose() {
    setShowViewSection(false);
    setShowProcessingOrder(false);
    setShowRefundedOrder(false)
    setshowExchangeOrder(false)
  }

  function buttonIcon() {
    switch (status) {
      case "pending":
        return <RiGiftLine />;

      case "dispatched":
        return <RiDiscordLine />;

      case "delivered":
        return <RiTruckLine />;

      default:
        return <RiRepeatLine />;
    }
  }
  
  async function handleStatusChange(id) {
    switch (status) {
      case "pending":
        await axiosInstance.patch(
          ORDERS_API + "dispatchedFromPending/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      case "dispatched":
        await axiosInstance.patch(
          ORDERS_API + "deliveredFromDispatch/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      case "delivered":
        await axiosInstance.patch(ORDERS_API + "refundedFromDelivered/" + id, "");
        triggerFetch();
        handleClose();

        return;


      case "exchanged":
        await axiosInstance.patch(ORDERS_API +"exchangedFromDelivered/" + id, "");
        triggerFetch();
        handleClose();

        return;

      default:
        return console.log("Status Not Changed");
    }
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">{`${status?.toUpperCase()} Orders  ${orders?.length <= 40 ? orders.length: parseInt(totalPages) * limit}
         in total)`}</h1>
        {/* <CreateButton
                    screenTopicSingular={'FAQ'}
                    setShowCreateForm={setShowCreateForm}
                /> */}

        <div>
          <input
           value={search}
           onChange={(e)=> setSearch(e.target.value)}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine style={{width:"30px", height:"30px", marginLeft:"-50px", color:"var(--white-2)"}}/>
        </div>

      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Name" />
          <CRUDth th="Order Total" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {orders?.map((order) => {
            console.log(order)
            return <CRUDTableRow key={order._id}>
              <ShortTextCell text={order.invoiceNo} />
              <ShortTextCell text={new Date(order?.createdAt).toDateString()} />
              <ShortTextCell text={order.shippingName} />
              <ShortTextCell text={takaFormatter(order?.orderTotal)} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={order._id}
                      setTargetID={setTargetID}
                      tooltip={"View Order"}
                    />
                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/order_items_from_order/" + order._id)
                      }
                      tooltip={"Order Items From Order"}
                    >
                      <RiListSettingsLine />
                    </CRUDButton>
                    {(status !== "refunded" && status !== "exchanged") && (
                      <CRUDButton
                        handleClick={() => {
                          setShowChangeOrderStatus(true);
                          setTargetID(order._id);
                        }}

                        tooltip={"Change Order Status"}
                      >
                       <RiExchangeLine />
                      </CRUDButton>
                    )}
                    {(status === "pending") && (
                      <CRUDButton
                        handleClick={() => {
                          setShowShippingMedia(true);
                          setTargetID(order._id);
                        }}

                        tooltip={"Add Shipping Media"}
                      >
                       <RiShip2Line />
                      </CRUDButton>
                    )}
                    {(status === "pending" || status === "dispatched") && (
                      <CRUDButton
                        handleClick={() => {
                          setShowProcessingOrder(true);
                          setTargetID(order._id);
                        }}
                        tooltip={"Precessing Order"}
                      >
                        {buttonIcon()}
                      </CRUDButton>
                    )}
                    {status === "delivered" && (
                      <>
                        <CRUDButton
                          handleClick={() => {
                            setShowRefundedOrder(true);
                            setTargetID(order._id);
                          }}
                          tooltip={"Delivered"}
                        >
                          {buttonIcon()}
                        </CRUDButton>
                        <CRUDButton
                          handleClick={() => {
                            setshowExchangeOrder(true);
                            setTargetID(order._id);
                          }}
                          tooltip={"Exchanged Order"}
                        >
                         <RiLuggageCartFill/>
                        </CRUDButton>
                      </>
                    )}
                    
                    {status !== "completed" && status !== "canceled" && (
                      <CRUDButton
                        handleClick={() => {
                          setShowCancelOrder(true);
                          setTargetID(order._id);
                        }}
                        tooltip={"Cancele"}
                      >
                        <RiCloseCircleLine />
                      </CRUDButton>
                    )}

                      <CRUDButton
                           handleClick={() => {
                            setShowInvoice(true);
                            setTargetID(order._id);
                          }}
                          tooltip={"Print for Customer"}
                        >
                           <RiPrinterLine />
                      </CRUDButton>

                      <CRUDButton
                           handleClick={() => {
                            setShowInvoicepkg(true);
                            setTargetID(order._id);
                          }}
                          tooltip={"Print for Packaging"}
                        >
                           <RiPrinterLine />
                      </CRUDButton>

                  </div>
                </div>
              </td>
            </CRUDTableRow>
          })}
        </tbody>
        <Pagination items={orders} totalPages={totalPages} limits={limit} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </CRUDTable>
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Order`}
          setShowModalContent={setShowViewSection}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}
      {showInvoicepkg && (
        <Modal
          // extraClass={"small"}
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoicepkg}
        >
          <OrderInvoicePackage targetID={targetID} />
        </Modal>
      )}

      {showInvoice && (
        <Modal
          // extraClass={"small"}
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoice}
        >
          <OrderInvoice targetID={targetID} />
        </Modal>
      )}

      {showProcessingOrder && (
        <Modal
          extraClass={"small"}
          modalHeading={`Precessing Order`}
          setShowModalContent={setShowProcessingOrder}
        >
          <OrderDelivered
            handleStatusChange={handleStatusChange}
            targetID={targetID}
            setShowProcessingOrder={setShowProcessingOrder}
          />
        </Modal>
      )}
      {showRefundedOrder && (
        <Modal
          extraClass={"small"}
          modalHeading={`Refunded From Delivered`}
          setShowModalContent={setShowRefundedOrder}
        >
          <RefundedFromDelivered
            handleStatusChange={handleStatusChange}
            targetID={targetID}
            setShowRefundedOrder={setShowRefundedOrder}
          />
        </Modal>
      )}
      {showExchangeOrder && (
        <Modal
          extraClass={"small"}
          modalHeading={`Refunded From Delivered`}
          setShowModalContent={setshowExchangeOrder}
        >
          <ExchangedFromDelivered
          triggerFetch={triggerFetch}
            targetID={targetID}
            setshowExchangeOrder={setshowExchangeOrder}
          />
        </Modal>
      )}
      {showCancelOrder && (
        <Modal
          extraClass={"small"}
          modalHeading={`Cancel Order`}
          setShowModalContent={setShowCancelOrder}
        >
          <CancelOrder
            targetID={targetID}
            setShowCancelOrder={setShowCancelOrder}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showChangeOrderStatus && (
        <Modal
          extraClass={"small"}
          modalHeading={`Change Order Status`}
          setShowModalContent={setShowChangeOrderStatus}
        >
          <ChangeOrderStatus
            targetID={targetID}
            setShowChangeOrderStatus={setShowChangeOrderStatus}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showShippingMedia && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Shipping Media`}
          setShowModalContent={setShowShippingMedia}
        >
          <AddShippingMedia
            targetID={targetID}
            setShowShippingMedia={setShowShippingMedia}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </>
    // <h2>pending</h2>
  );
}

export default OrdersFromStatus;
