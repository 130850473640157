import { RiCaravanLine,  RiGuideLine, RiImageEditLine, RiOrderPlayLine, RiRefund2Line, RiUser2Line } from "react-icons/ri"
import NavCardList from "../../../../Partials/Layouts/NavCardList/NavCardList"
import NavCard from "../../../../Partials/Layouts/NavCardList/NavCard/NavCard"

export const faqRoute = '/main/faq/'

function FAQScreens() {
    return (
        <NavCardList numOfCards='eight'>
            <NavCard cardName='Order-Issues' navCardLink={faqRoute + 'orderIssues'}><RiOrderPlayLine /></NavCard>
            <NavCard cardName='Membership-Scheme' navCardLink={faqRoute + 'membershipScheme'}><RiUser2Line /></NavCard>
            <NavCard cardName='Delivery' navCardLink={faqRoute + 'delivery'}><RiCaravanLine /></NavCard>
            <NavCard cardName='Shipping-Delivery' navCardLink={faqRoute + 'shippingDelivery'}><RiRefund2Line /></NavCard>
            <NavCard cardName='Exchanges-Returns-Refunds' navCardLink={faqRoute + 'exchangeReturnsRefunds'}><RiRefund2Line /></NavCard>
            <NavCard cardName='Product-SizeGuides' navCardLink={faqRoute + 'productSizeGuides'}><RiGuideLine /></NavCard>
            <NavCard cardName='Technical' navCardLink={faqRoute + 'technical'}><RiImageEditLine /></NavCard>
            <NavCard cardName='Contact-Us' navCardLink={faqRoute + 'contact'}><RiImageEditLine /></NavCard>
        </NavCardList>
    )
}

export default FAQScreens