import { useEffect, useState, useRef } from "react"
import { PRODUCTS_API, PAIREDPRODUCT_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import './pairedproduct.css'

function CreatePairedProduct({ productID, triggerFetch, setShowCreateForm }) {
    const axiosInstance = useAxiosInstance();
    const [product2, setProduct2] = useState("");
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Search term state
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown toggle

    const dropdownRef = useRef(null); // Reference for handling clicks outside dropdown

    useEffect(() => {
        async function fetchAndSetPairedProducts() {
            try {
                const response = await axiosInstance.get(PRODUCTS_API);
                const data = Array.isArray(response.data.products) ? response.data.products : [];
                const filteredData = data.filter(
                    product => product._id.toString() !== productID.toString()
                );
                setProducts(filteredData);
                setFilteredProducts(filteredData);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        }
        fetchAndSetPairedProducts();
    }, [axiosInstance, productID]);

    useEffect(() => {
        const results = products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(results);
    }, [searchTerm, products]);

    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const itemData = new FormData();

        itemData.append("product1", productID);
        itemData.append("product2", product2);

        const response = await axiosInstance.post(PAIREDPRODUCT_API, itemData);
        if (response) {
            triggerFetch();
            setShowCreateForm(false);
        }
    }

    return (
        <div className="create_form">
            <Form onSubmit={handleSubmit}>
                <div style={{ position: "relative" }} ref={dropdownRef}>
                    {/* <label htmlFor="paired-product">Select Paired Product</label> */}
                    <div
                        className="dropdown-toggle"
                        onClick={() => setIsDropdownOpen(prev => !prev)}
                        style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "#fff",
                            width: "100%",
                            marginTop: "10px"
                        }}
                    >
                        {product2
                            ? filteredProducts.find(product => product._id === product2)?.name ||
                              "Select Paired Product"
                            : "Select Paired Product"}
                    </div>
                    {isDropdownOpen && (
                        <div
                            className="dropdown-menu"
                            style={{

                                borderRadius: "4px",
                                zIndex: 1000,
                                overflowY: "auto",
                                background: "#f7f7f7",
                            }}
                        >
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                placeholder="Search Product"
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                    marginBottom: "4px",
                                    border: "none",
                                    borderBottom: "1px solid #ccc"
                                }}
                            />
                            {filteredProducts.length > 0 ? (
                                filteredProducts.map(product => (
                                    <div
                                        key={product._id}
                                        onClick={() => {
                                            setProduct2(product._id);
                                            setIsDropdownOpen(false);
                                        }}
                                        style={{
                                            padding: "8px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {product.name || "Unnamed Product"}
                                    </div>
                                ))
                            ) : (
                                <div
                                    style={{
                                        padding: "8px",
                                        textAlign: "center",
                                        color: "#999"
                                    }}
                                >
                                    No products found
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <FormSubmitButton text="Add Paired Product" />
            </Form>
        </div>
    );
}

export default CreatePairedProduct;