import { useEffect, useRef, useState } from "react";
// zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { RiFileZipLine, RiImage2Line, RiSearchLine } from "react-icons/ri";
import axios from "axios";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  CATAGORY_API,
  ORDERS_API,
  PRODUCTS_API,
  PRODUCT_IMAGES_API,
} from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Image from "../../../Partials/Elements/Image/Image";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../ProductSetup/Products/CreateProduct";
import UpdateProduct from "../ProductSetup/Products/UpdateProduct";
import ViewProduct from "../ProductSetup/Products/ViewProduct";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";

function TopSoldProducts() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // FOR DATE
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [productImages, setProductImages] = useState(null);
  console.log("🚀 ~ Products ~ productImages:", productImages);

  // Pagination states

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");

  const [catagories, setCatagories] = useState(null);




  useEffect(() => {
    if (gender) {
      async function fetchAndSetCatagories() {
        const { data } = await axiosInstance.get(
          CATAGORY_API + "getCategoryByGender/" + gender
        );
        setCatagories(data);
      }
      fetchAndSetCatagories();
    }
  }, [gender])

  useEffect(() => {
    async function fetchAndSetProducts() {
      // const { data } = await axiosInstance.get(PRODUCTS_API + '?search=' +search);
      const res = await axiosInstance.get(
        `${ORDERS_API}getTopSaleOrdersProducts?&search=${search}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${limit}&gender=${gender}&category=${category}`
      );

      console.log("res", res);
      setProducts(res?.data?.productsWithQuantities);
      setTotalItems(res?.data?.totalItems);
    }
    fetchAndSetProducts();
  }, [
    toggleFetch,
    axiosInstance,
    search,
    currentPage,
    gender,
    category,
    startDate,
    endDate,
  ]);

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Top Sold Products ({totalItems} in total)
        </h1>
        {/* <div>
          <input
            value={searchInput}
            onChange={(e) => {
              // handleSearchInput(e.target.value);
              setSearchInput(e.target.value);
            }}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div> */}

        <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
        />

        <DateInput label={"End Date"} value={endDate} setState={setEndDate} />

        <SelectInput label={"Gender"} value={gender} setState={setGender}>
          <SelectOption optionValue="" optionText="Select Gender" />
          <SelectOption optionValue="men" optionText="Men" />
          <SelectOption optionValue="women" optionText="Women" />
          <SelectOption optionValue="unisex" optionText="Unisex" />
          <SelectOption optionValue="pets" optionText="Pets" />
        </SelectInput>
        <SelectInput 
          label={"Category"} 
          value={category} 
          setState={setCategory}>
          <SelectOption optionValue="" optionText="Select Category" />
          {catagories?.map((category) => (
            <SelectOption
              optionValue={category._id}
              key={category._id}
              optionText={category?.categoryName}
            />
          ))}
        </SelectInput>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Category" />
          <CRUDth th="Name" />
          <CRUDth th="Price" />
          <CRUDth th="Quantity" />
          <CRUDth th="Total" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((product) => (
              <CRUDTableRow key={product?.product?._id}>
                <ShortTextCell
                  text={product?.product?.category?.categoryName}
                />
                <ShortTextCell text={product?.product?.name} />
                <ShortTextCell text={product?.product?.price} />
                <ShortTextCell text={product?.totalQuantitySold} />
                <ShortTextCell
                  text={product?.product?.price * product?.totalQuantitySold}
                />
              </CRUDTableRow>
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProduct
            // triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProduct
            // triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProduct targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCTS_API}
            // triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default TopSoldProducts;
