import { useEffect, useRef, useState } from "react";
// zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { RiFileZipLine, RiImage2Line, RiListSettingsLine, RiPrinterLine, RiSearchLine } from "react-icons/ri";
import axios from "axios";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ORDERS_API,
  PRODUCTS_API,
  PRODUCT_IMAGES_API,
} from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Image from "../../../Partials/Elements/Image/Image";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../ProductSetup/Products/CreateProduct";
import UpdateProduct from "../ProductSetup/Products/UpdateProduct";
import ViewProduct from "../ProductSetup/Products/ViewProduct";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ViewOrder from "./ViewOrder";
import { takaFormatter } from "../../../../Utilities/Formatter";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import OrderInvoice from "../../../Partials/Elements/OrderInvoice/OrderInvoice";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import OrderInvoicePackage from "../../../Partials/Elements/OrderInvoice/Orderinvoicepackage";

function AllOrders() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false)
  const [showInvoicePKG, setShowInvoicePKG] = useState(false)

  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [orderStatus, setOrderStatus] = useState('')



  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Pagination states

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

  //   setStartDate(formattedDate);
  //   setEndDate(formattedDate);
  // }, []);

  useEffect(() => {
    async function fetchAndSetProducts() {
      // const { data } = await axiosInstance.get(PRODUCTS_API + '?search=' +search);
      const res = await axiosInstance.get(
        `${ORDERS_API}getAllOrders?page=${currentPage}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}`
      );

      console.log("res", res);
      setProducts(res?.data?.orders);
      setTotalItems(res?.data?.totalItems);
    }
    fetchAndSetProducts();
  }, [toggleFetch, axiosInstance, search, currentPage, startDate, endDate, orderStatus]);


  const handleSearchInput = (value) => {
    const timeoutId = setTimeout(() => {
      setSearch(value);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }



  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Orders ({totalItems} in total)</h1>
        <div>
          <input
            value={searchInput}
            onChange={(e) => {
              handleSearchInput(e.target.value);
              setSearchInput(e.target.value);
            }}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div>
        <SelectInput  value={orderStatus} setState={setOrderStatus}>
            <SelectOption optionValue="" optionText="Select Order Status" />
            <SelectOption optionValue="pending" optionText="Pending" />
            <SelectOption optionValue="dispatchedInHouse" optionText="Dispatched (InHouse)" />
            <SelectOption optionValue="dispatchedCourier" optionText="Dispatched (Courier)" />
            <SelectOption optionValue="delivered" optionText="Delivered" />
            <SelectOption optionValue="deliveredUnpaid" optionText="Delivered (Unpaid)" />
            <SelectOption optionValue="refunded" optionText="Refunded" />
            <SelectOption optionValue="exchanged" optionText="Exchanged" />
            <SelectOption optionValue="canceled" optionText="Canceled" />
          </SelectInput>
        {/* <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
        />

        <DateInput label={"End Date"} value={endDate} setState={setEndDate} /> */}
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Name" />
          <CRUDth th="Order Total" />
          <CRUDth th="Payment" />
          <CRUDth th="Status" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((order) => (
              <CRUDTableRow key={order?._id}>
                {console.log("order", order)}
                <ShortTextCell text={order?.invoiceNo} />
                <ShortTextCell
                  text={new Date(order?.createdAt).toDateString()}
                />
                <ShortTextCell text={order?.shippingName} />
                <ShortTextCell text={Math.round(order?.orderTotal)} />
                <ShortTextCell text={order?.paymentMethod} />
                <ShortTextCell text={order?.orderStatus} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={order._id}
                        setTargetID={setTargetID}
                        tooltip={"View Order"}
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate("/main/order_items_from_order/" + order._id)
                        }
                        tooltip={"Order Items From Order"}
                      >
                        <RiListSettingsLine />
                      </CRUDButton>

                      <CRUDButton
                        handleClick={() => {
                          setShowInvoice(true);
                          setTargetID(order._id);
                        }}
                        tooltip={"Print for Customer"}
                      >
                        <RiPrinterLine />
                      </CRUDButton>

                      <CRUDButton
                        handleClick={() => {
                          setShowInvoicePKG(true);
                          setTargetID(order._id);
                        }}
                        tooltip={"Print for Packaging"}
                      >
                        <RiPrinterLine />
                      </CRUDButton>
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product`}
          setShowModalContent={setShowViewSection}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}

      {showInvoice && (
        <Modal
          // extraClass={"small"}
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoice}
        >
          <OrderInvoice targetID={targetID} />
        </Modal>
      )}

      {showInvoicePKG && (
        <Modal
          // extraClass={"small"}
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoicePKG}
        >
          <OrderInvoicePackage targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default AllOrders;
