import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";

function OrderInvoicePackage({ targetID }) {
  const axiosInstance = useAxiosInstance();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    async function fetchOrder() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "getSingleOrder/" + targetID
      );
      setOrder(data[0]);
    }
    fetchOrder();
  }, [axiosInstance, targetID]);

  const invoiceRef = useRef();

  const generatePDF = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `Invoice_${order?.invoiceNo}`,
  });

  return (
    <div>
      <button
        onClick={generatePDF}
        style={{
          padding: "12px 25px",
          backgroundColor: "#007BFF",
          color: "#FFF",
          border: "none",
          borderRadius: "5px",
          fontFamily: "Roboto, Arial, sans-serif",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        Print Invoice
      </button>
      <div
        ref={invoiceRef}
        style={{
          padding: "20px",
          marginBottom: "20px",
          backgroundColor: "#FFF",
          fontFamily: "Roboto, Arial, sans-serif",
          color: "#000",
          position: "relative",
        }}
      >
        {/* Internal CSS for printer settings */}
        <style>
          {`
            @page {
              size: 4in 6in; /* Explicitly set paper size for XPrinter */
              margin: 0; /* No margins for a full-bleed layout */
            }
            body {
              margin: 0;
              padding: 0;
              font-family: Roboto, Arial, sans-serif;
            }
            /* Watermark visible on print */
            @media print {
              .watermark {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 70px;
                font-weight: bold;
                color: rgba(200, 0, 0, 0.15);
                z-index: 0;
                pointer-events: none;
                text-transform: uppercase;
              }
            }
          `}
        </style>

        {/* Watermark */}
        {(order?.paymentMethod === "bkash" || order?.paymentMethod === "SSLCommerz") && (
          <div className="watermark" 
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "70px", 
            fontWeight: "bold",
            color: "rgba(200, 0, 0, 0.15)",
            zIndex: 0,
            pointerEvents: "none",
            textTransform: "uppercase",
          }}>

            PAID
          </div>
        )}

        {/* Header */}
        <div style={{ textAlign: "center", marginBottom: "20px", zIndex: 1 }}>
          <h1 style={{ margin: "0", fontSize: "22px", fontWeight: "bold" }}>
            Invoice
          </h1>
        </div>

        {/* Order Information */}
        <div style={{ marginBottom: "30px", zIndex: 1 }}>
          <p style={{ margin: "12px 0", fontSize: "18px" }}>
            <b>Order Number:</b> {order?.invoiceNo}
          </p>
          <p style={{ margin: "12px 0", fontSize: "18px" }}>
            <b>Order Date:</b>{" "}
            {new Date(order?.createdAt).toLocaleDateString()}
          </p>
        </div>

        {/* Customer Information */}
        <div style={{ marginBottom: "35px", zIndex: 1 }}>
          <h2 style={{ fontSize: "20px", marginBottom: "10px", color: "grey" }}>
            Customer Information
          </h2>
          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Name:</b> {order?.shippingName}
          </p>
          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Phone:</b> {order?.shippingPhone}
          </p>
          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Address:</b> {order?.shippingAddress}, {order?.customerArea},{" "}
            {order?.customerDistrict}, Bangladesh
          </p>
        </div>

        {/* Order Total and Payment Method */}
        <div style={{ marginBottom: "35px", zIndex: 1 }}>
          <h2 style={{ fontSize: "20px", marginBottom: "10px", color: "grey" }}>Order Summary</h2>
          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Total Amount:</b> ৳ {Math.round(order?.orderTotal)}
          </p>
          <p style={{ margin: "10px 0", fontSize: "18px" }}>
          <b>Delivery Method:</b>{" "}
          <span
                style={{
                color:
                    order?.shippingMethod === "express"
                    ? "red"
                    : "black",
                }}
            >
            {order?.shippingMethod}
            </span>
            </p>

          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Payment Method:</b>{" "}
            <span
                style={{
                color:
                    order?.paymentMethod === "bkash" || order?.paymentMethod === "SSLCommerz"
                    ? "red"
                    : "black",
                }}
            >
                {order?.paymentMethod === "cash"
                ? "Cash on Delivery"
                : order?.paymentMethod === "bkash"
                ? "Paid via Bkash"
                : order?.paymentMethod === "SSLCommerz"
                ? "Paid via SSLCommerz"
                : order?.paymentMethod}
            </span>
            </p>

          <p style={{ margin: "10px 0", fontSize: "18px" }}>
            <b>Additional Instructions:</b> {order?.additionalInstructions}
          </p>
        </div>

        {/* Footer */}
        <div style={{ textAlign: "center", marginTop: "40px", fontSize: "16px" }}>
            <p
              style={{
                margin: "0",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#f26822",
                textAlign: "center"
              }}
            >
              {order?.gifted === "true" ? "Gift" : ""}
            </p>
          <p>Thank you for your order!</p>
        </div>
      </div>
    </div>
  );
}

export default OrderInvoicePackage;
